import styles from './index.module.scss'
import cx from 'classnames'

function About() {
  return <div className={styles.page}>
    {
      window.isMobile && <div className={styles.pTitle}>About</div>
    }
    <div className={styles.section1}>
      {/* <img className={styles.bg} src="/images/aboutBg.png" alt="" /> */}
      <iframe className={styles.iframe} width="640" height="360" src="https://www.youtube.com/embed/QxFVJ-k2xHc?si=WxdL5zemAJvqmA6l&autoplay=1&rel=0&controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen ></iframe>

      <div className={styles.textWrap}>
        {
          window.isMobile ? <div data-aos="fade-left">
            <div className={styles.row}>DIN is the Data Intelligence</div>
            <div className={styles.row}>Network that Empowers AI</div>
            <div className={styles.row}>with Crypto</div>
          </div>
            :
            <div data-aos="fade-left">
              <div className={styles.row}>DIN is the</div>
              <div className={styles.row}>Data Intelligence Network that</div>
              <div className={styles.row}>Empowers AI with Crypto</div>
            </div>
        }

        <div className={styles.smallText} data-aos="fade-left" data-aos-delay="300">The first network with protocols and communities for the revolution of data flow and AI agent building.</div>
      </div>
    </div>
    <div className={styles.section2}>
      <div className={styles.title}>
        <span className={styles.splitL}></span>
        <span className={styles.text}>Backed By</span>
        <span className={styles.splitR}></span>
      </div>
      {window.isMobile ? <div className={styles.icons}>
        <div className={styles.row}>
          <img src="/images/b1.png" alt="" />
          <img src="/images/b2.png" alt="" />
          <img src="/images/b3.png" alt="" />
          <img src="/images/b4.png" alt="" />
        </div>
        <div className={styles.row}>
          <img src="/images/b5.png" alt="" />
          <img src="/images/b6.png" alt="" />
          <img src="/images/b7.png" alt="" />
          <img src="/images/b8.png" alt="" />
        </div>
      </div> :
        <div className={styles.icons}>
          <img src="/images/b1.png" alt="" />
          <img src="/images/b2.png" alt="" />
          <img src="/images/b3.png" alt="" />
          <img src="/images/b4.png" alt="" />
          <img src="/images/b5.png" alt="" />
          <img src="/images/b6.png" alt="" />
          <img src="/images/b7.png" alt="" />
          <img src="/images/b8.png" alt="" />
        </div>}

    </div>
    <div className={styles.section3}>
      <div className={styles.title}>
        How DIN works
      </div>
      <div className={styles.content}>
        {
          window.isMobile ?
            <img src="/images/workflowM.webp" alt="" />
            :
            <img src="/images/workflow.png" alt="" />
        }

      </div>
    </div>
    <div className={styles.section4}>
      <div className={styles.title}>Why a new “AI+Crypto”</div>
      <div className={styles.list}>
        <div className={styles.item}>
          <img src="/images/w1.png" alt="" />
          <span>Data Collaboration</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w2.png" alt="" />
          <span>Fair Compensation</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w3.png" alt="" />
          <span>Ownership</span>
        </div>
        <div className={styles.item}>
          <img src="/images/w4.png" alt="" />
          <span>Intent-Centric</span>
        </div>
      </div>
    </div>
  </div >
}

export default About;
