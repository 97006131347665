import styles from './index.module.scss'
import cx from 'classnames'
import { Link, Outlet } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import React from 'react';
function Layout() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  useEffect(() => {
    AOS.init({
      offset: -200
    });
  }, [])
  const addXDin = async () => {
    // const requiredChain = bsc;
    // const chainIdFromExtension = Number(await getExtensionChainId()); // from bigint to number
    // if (chainIdFromExtension !== requiredChain.id) {
    //   await switchAndAddChain(requiredChain, true);
    // }
    // await addToken();
  };

  if (window.isMobile) {
    return <div className={styles.mobileLayout}>
      <div className={styles.header}>
        <Link to="/">
          <img className={styles.logo} src="/images/logo.png" alt="" />
        </Link>
        <img onClick={() => setDrawerVisible(true)} className={cx(styles.dropdownIcon, 'hover-item')} src="/images/dropdownIcon.svg" alt="" />
        <div className={cx(styles.nav, { [styles.show]: drawerVisible })}
        >
          <div className={styles.header}>
            <Link to="/">
              <img className={styles.logo} src="/images/logo.png" alt="" />
            </Link>
            <img onClick={() => setDrawerVisible(false)} className={cx(styles.closeIcon, 'hover-item')} src="/images/close.svg" alt="" />
          </div>

          <Link onClick={() => setDrawerVisible(false)} to="/about" className={cx(styles.item, 'hvr-underline-from-center')}>About</Link>
          <Link onClick={() => setDrawerVisible(false)} to="/products" className={cx(styles.item, 'hvr-underline-from-center')}>Product</Link>
          <Link onClick={() => setDrawerVisible(false)} to="/career" className={cx(styles.item, 'hvr-underline-from-center')}>Career</Link>
          <a onClick={() => setDrawerVisible(false)} href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
            <span>Docs</span>
            <img src="/images/share.png" alt="" />
          </a>
          <a onClick={() => setDrawerVisible(false)} href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
            <span>Blog</span>
            <img src="/images/share.png" alt="" />
          </a>
        </div>
      </div>
      <div className={styles.mMain}><Outlet /></div>
      <div className={styles.mFooter}>
        <div className={styles.icons}>
          <a className='hover-item' href="https://twitter.com/din_lol_" target="_blank">
            <img src="/images/twitter.png" alt="" />
          </a>
          <a className='hover-item' href="https://discord.gg/dinlol" target="_blank">
            <img src="/images/discord.png" alt="" />
          </a>
          <a className='hover-item' href="https://t.me/DINCommunity " target="_blank">
            <img src="/images/telegram.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.medium.com/ " target="_blank">
            <img src="/images/medium.png" alt="" />
          </a>
          <a className='hover-item' href="https://www.youtube.com/@din_lol_  " target="_blank">
            <img src="/images/youtube.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank">
            <img src="/images/book.png" alt="" />
          </a>
          <a className='hover-item' href="https://github.com/web3go-xyz" target="_blank">
            <img src="/images/github.png" alt="" />
          </a>
        </div>
        <div className={styles.row1}>
          <a href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.subscribe, 'hover-item')}>
            Subscribe Newsletter &gt;
          </a>
          {/* <div className={cx(styles.addXDin, 'hover-item')} onClick={addXDin}>
            <img src="/images/metamask.png" alt="" />
            <span>Add xDIN</span>
          </div> */}
        </div>
        <div className={styles.row2}>
          <a className='hover-item' href="https://din.lol/privacy-policy.html" target='_blank'>Privacy Policy</a>
          <a className='hover-item' href="https://din.lol/terms-conditions.html" target='_blank'>Terms of Use</a>
        </div>
      </div>
    </div>
  }


  const [showNodeEntry, setShowNodeEntry] = React.useState(true);



  return <div className={styles.layoutPage}>
    {
      showNodeEntry && <a href="https://node.din.lol/" target='_blank' className={cx(styles.nodeEntry, 'hover-item')}>
        <img src="/images/entrance2.png" alt="" />
        <svg onClick={(e) => { e.preventDefault(); setShowNodeEntry(false) }} className='hover-item' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.125 3.2207L16.8184 1.91406L10.0195 8.71289L3.2207 1.91406L1.91406 3.2207L8.71289 10.0195L1.91406 16.8184L3.2207 18.125L10.0195 11.3262L16.8184 18.125L18.125 16.8184L11.3262 10.0195L18.125 3.2207Z" fill="#131313" />
        </svg>
      </a>
    }

    <div className={styles.header}>
      <Link to="/">
        <img className={styles.logo} src="/images/logo.png" alt="" />
      </Link>
      <div className={styles.hRight}>
        <Link to="/about" className={cx(styles.item, 'hvr-underline-from-center')}>About</Link>
        <Link to="/products" className={cx(styles.item, 'hvr-underline-from-center')}>Product</Link>
        <Link to="/career" className={cx(styles.item, 'hvr-underline-from-center')}>Career</Link>
        <a href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
          <span>Docs</span>
          <img src="/images/share.png" alt="" />
        </a>
        <a href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.item, 'hvr-underline-from-center')}>
          <span>Blog</span>
          <img src="/images/share.png" alt="" />
        </a>
      </div>
    </div>
    <div className={cx(styles.main, { [styles.showNodeEntry]: showNodeEntry })}>
      <Outlet />
    </div>
    <div className={styles.footer}>
      <div className={styles.inner}>
        <a href="https://dinlol.medium.com/ " target="_blank" className={cx(styles.subscribe, 'hover-item')}>
          Subscribe Newsletter &gt;
        </a>
        <div className={styles.icons}>
          <a className='hover-item' href="https://twitter.com/din_lol_" target="_blank">
            <img src="/images/twitter.png" alt="" />
          </a>
          <a className='hover-item' href="https://discord.gg/dinlol" target="_blank">
            <img src="/images/discord.png" alt="" />
          </a>
          <a className='hover-item' href="https://t.me/DINCommunity " target="_blank">
            <img src="/images/telegram.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.medium.com/ " target="_blank">
            <img src="/images/medium.png" alt="" />
          </a>
          <a className='hover-item' href="https://www.youtube.com/@din_lol_  " target="_blank">
            <img src="/images/youtube.png" alt="" />
          </a>
          <a className='hover-item' href="https://dinlol.gitbook.io/din-cook-data-for-ai " target="_blank">
            <img src="/images/book.png" alt="" />
          </a>
          <a className='hover-item' href="https://github.com/web3go-xyz" target="_blank">
            <img src="/images/github.png" alt="" />
          </a>
        </div>
        <div className={styles.fr}>
          <div className={styles.frb}>
            <a className='hover-item' href="https://din.lol/privacy-policy.html" target='_blank'>Privacy Policy</a>
            <a className='hover-item' href="https://din.lol/terms-conditions.html" target='_blank'>Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  </div >
}


export default Layout;
